<template>
  <div class="pt-3">
    <Loader v-if="performingRequest" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <div>
          <h4>Add Sub Client:</h4>
          <div class="mb-3">
            <label for="title">Title</label>
            <input type="text" v-model="client.title"></input>
          </div>
          <div class="mb-3" v-if="clients.length >= 1">
            <label for="client">Parent Client:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="clients"
              multiple
              v-model="client.parent"
              >
            </v-select>
          </div>
          <div class="mb-3">
            <span class="mr-3 mb-2">
              <input type="file" ref="fileInputHS" id="ClientImage" accept="image/*,application/pdf,.doc" @change="previewHS" hidden>
              <div>
                <button @click="chooseClientImage()" class="btn btn__primary btn__xsmall">Upload Image</button>
              </div>
              <div>
                <progress :value="uploadValue" max="100" v-if="showBar"></progress>
              </div>
            </span>
          </div>
          <div class="mb-3">
            <button class="btn btn__primary" @click="addSubClient()">
              Add Sub Client
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__container--body">
      <h4 class="mb-3">Sub Clients:</h4>
      <vue-good-table
        :columns="columns"
        :rows="subClients"
         styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'title', type: 'asc'}
        }"
      >

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'url'">
          <span v-if="props.row.url">
            <a :href="props.row.url" target="_blank">
              <i class="fas fa-external-link ml-2 mr-2"></i>
            </a>
          </span>
        </span>
        <span v-else-if="props.column.field == 'title'">
          <router-link :to="`/settings/certs/` + props.row.id">
            {{props.row.title}}
          </router-link>
        </span>
        <span v-else-if="props.column.field == 'parent'">
          <span v-if="props.row.parent && props.row.parent[0]">{{props.row.parent[0].title}}</span>
        </span>
        <span v-else-if="props.column.field == 'logo'">
          <a :href="props.row.logo" target="_blank">{{props.row.logo}}</a>
          
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
      </vue-good-table>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'manageSubClients',
  data: () => ({
    client: {},
    performingRequest: false,
    showBar: false,
    columns: [
      {
        label: 'Title',
        field: 'title',
        width: '100px',
        sortable: false
      },
      {
        label: 'Parent',
        field: 'parent',
        width: '200px',
        // sortable: false
      },
      {
        label: 'Logo',
        field: 'logo',
        sortable: false
      },
    ],
  }),
  created () {
    this.$store.dispatch("getSubClients")
    // this.$store.dispatch("updateEventJobNos")
    if (!this.clients || this.clients.length < 1) {
        this.$store.dispatch("getClients")
    }
    
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'subClients', 'clients']),
  },
  components: {
    Loader,
  },
  methods: {
    chooseClientImage() {
      document.getElementById("ClientImage").click()
    },
    previewHS(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadImage()
    },
    onUploadImage() {
      console.log('onUpload Health')
      this.showBar= true
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          this.client.logo = downloadURL
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.fileInputHS.value=null
    },
    async addSubClient() {
      this.performingRequest = true
      await fb.subClientsCollection.add(this.client)
      .then(
        doc => {
          fb.subClientsCollection.doc(doc.id).update({
            id: doc.id
          })
        }
      )
      this.client = {}
      this.performingRequest = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearSubClients")
    this.$store.dispatch('clearClientsState')
  }
}
</script>